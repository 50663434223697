import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTelecontrol from './+store/telecontrol.reducer';
import { TelecontrolEffects } from './+store/telecontrol.effects';

import { TelecontrolsRoutingModule } from './telecontrols-routing.module';

@NgModule({
  declarations: [],
  imports: [
    TelecontrolsRoutingModule,
    CommonModule,
    ClarityModule,
    ClrAddonsModule,
    StoreModule.forFeature(
      fromTelecontrol.telecontrolFeatureKey,
      fromTelecontrol.reducer,
    ),
    EffectsModule.forFeature([TelecontrolEffects]),
  ],
})
export class TelecontrolsModule {}
