import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'messages',
    loadComponent: () =>
      import(
        './telecontrols-messages-current/telecontrols-messages-current.component'
      ).then((m) => m.TelecontrolsMessagesComponent),
  },
  {
    path: 'messages/archive/:uid',
    loadComponent: () =>
      import(
        './telecontrols-messages-archive/telecontrols-messages-archive.component'
      ).then((m) => m.TelecontrolsMessagesArchiveComponent),
  },
  {
    path: 'presets',
    loadComponent: () =>
      import(
        './telecontrols-presets-current/telecontrols-presets-current.component'
      ).then((m) => m.TelecontrolsPresetsComponent),
  },
  {
    path: 'presets/archive/:uid',
    loadComponent: () =>
      import(
        './telecontrols-presets-archive/telecontrols-presets-archive.component'
      ).then((m) => m.TelecontrolsPresetsArchiveComponent),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TelecontrolsRoutingModule {}
